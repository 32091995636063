@media only screen and (max-width: 600px) {
  .navbar-container {
    height: 10vh;
  }
}

@media only screen and (min-width: 600px) {
  .navbar-container {
  }
}

@media only screen and (min-width: 768px) {
  .navbar-container {
  }
}

@media only screen and (min-width: 992px) {
  .navbar-container {
  }
}

@media only screen and (min-width: 1200px) {
  .navbar-container {
    height: 10vh;
  }
}
