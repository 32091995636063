.card-body {
  text-decoration: none;
  color: black;
}
.card-body:hover {
  text-decoration: none;
  color: black;
}
.card-body:visited {
  text-decoration: none;
  color: black;
}
.card-body:active {
  text-decoration: none;
  color: black;
}
.card {
  border: none;
  color: white;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  cursor: pointer;
}
.card:hover {
  box-shadow: 0px 17px 16px -11px #0e1116;
  transform: translateY(-5px);
}
.web-project-container {
  margin-top: 10vh;
}
@media (min-width: 1500px) {
  .card-body {
    position: relative;
    width: 100%;
  }
  .web-project-container {
    margin-right: 20vw;
  }
  .row {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
  }
}
