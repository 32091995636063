.arrow a {
  position: absolute;
  margin-top: 15vh;
  margin-left: 20vh;
  color: #8849c7;
  text-decoration: none;
}

.arrow {
  text-align: center;
  margin: 8% 0;
}
@media only screen and (max-width: 600px) {
  .arrow {
    display: none;
  }
}
#down-arrow {
  font-size: 10rem;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
