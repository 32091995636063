* {
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
body {
  font-family: "Sora", sans-serif;
}
button {
  font-family: "Sora", sans-serif;
}
