.computer {
  width: 35vw;
  transform: scale(1) translate(5%);
}
.data {
  width: 35vw;
  transform: scale(1) translate(-10%, 10%);
}

@media only screen and (max-width: 600px) {
  .computer {
    width: 110vw;
    transform: scale(1) translate(5%);
  }
}
@media only screen and (min-width: 600px) {
  .computer {
    width: 40vw;
    transform: scale(1) translate(5%);
  }
}

@media only screen and (min-width: 768px) {
  .computer {
    width: 45vw;
    transform: scale(1) translate(5%);
  }
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
